import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './App.css';
import './ListView.css'; // 新しく作成したCSSファイルをインポート
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';

const apiUrl = `http://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}`;

function ListView({ onSelectFile , fetchSidebarData, sidebarData}) {
  const [files, setFiles] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchSidebarData();
  }, [fetchSidebarData]);
  
  useEffect(() => {
    const fetchFiles = async () => {
      try {
        console.log('Fetching files from API...'); // デバッグ用のログ
        const response = await fetch(`/api/files`);
        const data = await response.json();
        console.log('Files fetched:', data); // デバッグ用のログ
        setFiles(data);
      } catch (error) {
        console.error('Error fetching files:', error);
      }
    };

    fetchFiles();
  }, []);

  const handleFileClick = async (fileId) => {
    console.log('File clicked:', fileId); // デバッグ用のログ

    // サーバーにfileIdを送信
    await fetch(`/api/logFileId`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fileId }),
    });

    // onSelectFile関数が正しく動作しているか確認
    const file = await onSelectFile(fileId);


    // サーバーにfileデータをログとして送信
    await fetch('/api/logFileData', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ file }),
    });

    navigate(`/?file-id=${fileId}`);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollToBottom = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  };

  const handleNewSlide = () => {
    const host = process.env.REACT_APP_API_HOST;
    const port = process.env.REACT_APP_SLIDE_PORT;
    const url = `https://${host}:${port}/`;
    window.open(url);
  };

  // sidebarDataが配列であることを確認
  const sidebarDataArray = Array.isArray(sidebarData) ? sidebarData : [];

  // 配列をソート
  sidebarDataArray.sort((a, b) => {
    // ソートのロジックをここに記述
  });

  return (
    <div className="App">
      <div className="header">
        {/* <div className="logo"><span className="icon"><a href="https://localhost:3000/list" target="_blank">📄</a></span>FOR UNOTE</div> */}
        <div className="toolbar">
          <button 
            style={{ backgroundColor: '#fff', border: '1px solid #b0c4de' }} 
            onClick={handleNewSlide}
            onMouseDown={(e) => e.target.style.backgroundColor = '#e0e0e0'}
            onMouseUp={(e) => e.target.style.backgroundColor = '#fff'}
          >
            New
          </button>
        </div>
      </div>
      <div className="container">
      <div className="file-list">
          {sidebarDataArray
            .sort((a, b) => b.id - a.id) // file.idの降順でソート
            .map((file, index) => (
              <div 
                key={index} 
                className="file-item" 
                onClick={() => {
                  console.log('File item clicked:', file); // デバッグ用のログ
                  handleFileClick(file.id);
                }}
              >
                <span className="icon">📄</span> {/* アイコンを表示 */}
                {/* <span className="file-id">{file.id}</span> idを表示 */}
                {file.file} {/* file名称を表示 */}
              </div>
          ))}
        </div>
      </div>
      <div className="scroll-buttons">
        <button 
          className="scroll-to-top" 
          onClick={scrollToTop}
        >
          <FaArrowUp />
        </button>
        <button 
          className="scroll-to-bottom" 
          onClick={scrollToBottom}
        >
          <FaArrowDown />
        </button>
      </div>
    </div>
  );
}

export default ListView;
